.journey-banner {
  background-image: url('../../assets/img/landing/vectores-29.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  width: 100%;
  margin-bottom: 30px;
}

.journey-button {
  width: 20%;
  background-color: transparent;
  border-color: white;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}

.journey-button:hover {
  color: #cea977;
}

@media (max-width: 767px) {
  .journey-button {
    width: 40%;
    background-color: transparent;
    border-color: white;
    color: white;
    font-weight: bold;
    border-radius: 10px;
  }
}
