* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.containerPB {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #000; */
}

.progressBarCustom {
  height: 10px;
  width: 100%;
  background-color: #e9ecef;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.progress-line {
  background: #6c6d6e;
  height: 8px;
  margin: auto 0;
  border-radius: 10px;
  transition: 0.9s ease-in-out;
}

.progress-circle-outter {
  width: 53px;
  height: 53px;
  background-color: hsla(41, 42%, 62%, 0.404);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -25px;
}

.progress-circle {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #bfaa7f;
  margin: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
  font-size: 11px;
}
