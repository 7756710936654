.buy-container {
  height: calc(95vh - 155px);
  width: 95vw;
  margin: auto;
  background-image: url('../../assets/img/landing/plantita-31.png');
  background-repeat: no-repeat;
  background-size: 150px;
  background-position-y: bottom;
  background-position-x: right;
}

.buy-button-problem {
  width: 20%;
  height: 100px;
  border-color: #cea977;
  background-color: #cea977;
  color: white;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
  border-radius: 7px;
  /* box-shadow: 1px 1px 10px 5px rgb(220, 220, 220) !important; */
}

.buy-button-problem:hover {
  color: #333 !important;
}

.buy-button-problem:focus {
  color: #333 !important;
}

.buy-button-deepdive {
  width: 20%;
  height: 100px;
  border-color: #cea977;
  background-color: #fafafa;
  color: #cea977;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
  border-radius: 7px;
  /* box-shadow: 1px 1px 10px 5px rgb(220, 220, 220) !important; */
}

.buy-button-deepdive:hover {
  color: #333 !important;
}

.buy-button-deepdive:focus {
  color: #333 !important;
}

.buy-paypal-button {
  background-image: url('../../assets//img/logos/paypal.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 70%;
  height: 80px;
  color: white;
}

.buy-stripe-button {
  background-image: url('../../assets//img/logos/stripe.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 70%;
  height: 80px;
  color: white;
}
