.bio-banner-photo {
  background-image: url(../../assets/img/photos/SA_photo.jpg);
  background-position: center;
  background-repeat: no-repeat;
  height: 45vh;
  filter: contrast(80%);
  background-size: cover;
}

.bio-section {
  width: 90%;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.about-button {
  width: 15%;
  background-color: black;
  color: white;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
  border-radius: 10px;
}

.about-button:hover {
  color: #cea977;
}

@media (max-width: 767px) {
  .about-button {
    width: 40%;
    background-color: black;
    color: white;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 25px;
    border-radius: 10px;
  }
}
