.description {
  width: 100%;
  height: 15vh;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 2%;
}

textarea:disabled {
  background: white;
}
