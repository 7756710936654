.burgerM a {
  text-decoration: none;
  margin: 30px 0;
  color: #000;
}

.burgerM a:active {
  color: #cea977;
}

.burgerItems ul {
  text-align: left;
  list-style: none !important;
  cursor: pointer !important;
}

.burgerItems ul > li:hover {
  color: #cea977 !important;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #cea977;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #cea977;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #fafafa;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  text-align: center;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #fafafa;
}

/* Wrapper for item list */
.bm-item-list {
  color: #000;
  padding: 0.8em;
}

.bm-item-list:focus {
  outline: none;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

.bm-item:focus {
  outline: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
