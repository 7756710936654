.banner-photo {
  background-image: url(../../assets/img/photos/SA_photo.jpg);
  background-position: center;
  background-repeat: no-repeat;
  height: 70vh;
  filter: contrast(80%);
  background-size: cover;
}

.bio {
  padding: 2% 15%;
  background-color: #fafafa;
}

.bio h2 {
  font-weight: bolder;
  margin: 30px auto;
}

.bio button {
  margin-top: 40px;
}

.quote-button {
  width: 20%;
  background-color: black;
  color: white;
  font-weight: bold;
  margin-top: 15px;
  border-radius: 10px;
}

.quote-button:hover {
  color: #cea977;
}

.quote-form {
  width: 30%;
}

@media (max-width: 767px) {
  .quote-button {
    width: 70%;
    background-color: black;
    color: white;
    font-weight: bold;
    margin-top: 15px;
    border-radius: 10px;
  }

  .quote-form {
    width: 70%;
  }
}

@media (min-width: 767px) and (max-width: 992px) {
  .quote-button {
    width: 50%;
    background-color: black;
    color: white;
    font-weight: bold;
    margin-top: 15px;
    border-radius: 10px;
  }

  .quote-form {
    width: 50%;
  }
}
