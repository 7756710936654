.login-form {
  width: 80%;
  padding: 3rem;
  border-radius: 0.5rem;
  background-color: white;
}

.login-input {
  padding: 5px 25px !important;
  border-radius: 6px !important;
  font-size: 0.9rem !important;
  margin: 10px 0px !important;
  background-color: white;
}

.login-button {
  width: 100%;
  background-color: black;
  color: white;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
}

.login-button:hover {
  color: #cea977;
}
