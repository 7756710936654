.home-login-banner {
  height: 600px;
  background-image: url('../../assets/img/landing/vectores-08.png');
  background-position: 10% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
}

.home-login-form {
  margin-left: auto;
}

.home-section {
  margin: 1rem 5%;
  padding-top: 2rem;
  border-top: 1px solid black;
}

.home-evidence-div {
  background-image: url('../../assets/img/landing/vectores-12.png');
  background-position-x: right;
  background-position-y: bottom;
  background-size: 50%;
  background-repeat: no-repeat;
  margin-bottom: 80px;
}

.home-problem-div {
  background-image: url('../../assets/img/landing/vectores-06.png');
  background-position-x: center;
  background-position-y: 1cm;
  background-size: 90%;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding-top: 50px;
  padding-bottom: 70px;
}

.home-divisor2 {
  background-image: url('../../assets/img/landing/vectores-09.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  margin-top: -1px !important;
}

.home-digital-div {
  background-color: #fafafa;
  background-position-x: center;
  background-position-y: 1cm;
  background-size: 90%;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 70px;
}

@media (max-width: 768px) {
  .home-login-form {
    margin-left: auto;
    margin-right: auto;
  }
}
