.academy-button {
  background-color: black;
  color: white;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
  border-radius: 10px;
}

.academy-button:hover {
  color: #cea977;
}

.academy-section {
  background-image: url('../../assets/img/landing/vectores-30.png');
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: 45%;
  width: 90%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
}

@media (max-width: 767px) {
  .academy-section {
    background-image: url('../../assets/img/landing/vectores-30.png');
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: 90%;
  }

  .academy-text {
    padding-top: 2%;
    padding-bottom: 60%;
    text-align: center;
  }
}
