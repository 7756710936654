.accordion-btn {
  border: none !important;
  background: transparent !important;
  text-align: left;
  padding: 4% 0;
  margin: 0;
  width: 100%;
}

.accordion-btn:focus {
  outline: 0px;
}

.sidebar {
  width: 20% !important;
  border-right: 1px solid #d6d6d6 !important;
  text-align: center;
}

.sidebar ul {
  list-style: none;
  cursor: pointer;
}

.sidebar ul > li:hover {
  color: #cea977 !important;
}

.optionHover:hover {
  color: #cea977 !important;
}

.sidebar-icons {
  width: 23px !important;
  height: 23px !important;
  margin-right: 5px;
}

.sidebar-logo {
  width: 80%;
  margin: 10% 10% 0 10%;
}

.sidebar .options {
  text-align: left;
  margin: 15% 15% 15% 10% !important;
  font-size: 18px;
  font-weight: bolder;
}

.level-1 {
  padding-left: 4%;
}

.level-2 {
  padding-left: 8px;
  font-size: 15px;
}

.level-3 {
  padding-left: 20%;
  font-size: 15px;
  font-weight: 400;
}
