.Q6 td input[type='radio'] {
  display: none;
}

.Q6 td label {
  padding: 10px 5px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.Q6 td label::before {
  content: '';
  width: 20px;
  height: 20px;
  background: none;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid #c7c7c7;
  position: absolute;
  right: 40px;
}

.Q6 td input[type='radio']:checked + label::before {
  content: '';
  width: 24px;
  height: 24px;
  background: url(../../../../assets/img/icons/check.png) no-repeat;
  background-size: cover;
  border: none;
  border-radius: none;
}
.Q6 .radio-div {
  display: flex;
  justify-content: flex-end;
}
