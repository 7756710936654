.nav-item .nav-link {
  color: #6a6a6a;
  font-size: 0.8rem;
  font-family: Lato-Regular;
}

.nav-item .active {
  color: #cea977;
  font-size: 0.8rem;
  border-bottom: 5px solid #cea977;
}

.nav-button {
  border: 1px solid grey;
  border-radius: 8px !important;
  padding: 3px 10px !important;
  font-size: 0.9rem !important;
  font-family: Lato-Light;
}

.nav-button:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.nav a:hover {
  color: #cea977 !important;
}

.navbar-logo {
  width: 17vw;
}

.nav-dropdown {
  border-style: none;
  color: #6a6a6a;
  background-color: white;
  padding: 3px 10px !important;
  font-size: 0.9rem;
  font-family: Lato-Regular;
  margin-right: 30px;
  border-radius: 19px;
}

.nav-dropdown:focus {
  outline: 0px;
}

.nav-dropdown:active {
  color: #cea977;
}

@media (max-width: 767px) {
  .navbar-logo {
    width: 40vw;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .navbar-logo {
    width: 28vw;
  }
}
