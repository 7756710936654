.ScatterChart-cuadro {
  width: 40vh;
  padding: 20px;
}

.ScatterChart-title {
  font-family: Lato-Bold;
  font-size: 1rem;
  text-decoration: underline;
  text-decoration-color: #cea977;
}

.ScatterChart-circle {
  background-color: #cea977;
  border-radius: 100px;
  width: 10px;
  height: 10px;
}

.ScatterChart-circle2 {
  background-color: black;
  border-radius: 100px;
  width: 10px;
  height: 10px;
}

.apexcharts-legend-series {
  display: flex !important;
  justify-content: left !important;
  align-items: center !important;
  margin: 10px 0px !important;
}

.apexcharts-legend {
  display: flex;
  justify-content: center !important;
}

.apexcharts-yaxis {
  margin-right: 20px !important;
}
