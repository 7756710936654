.footer {
  margin: 1rem 5%;
  padding-top: 2rem;
  border-top: 1px solid black;
  margin-top: 0px;
}

.footer1 {
  width: 90%;
  font-size: 1rem;
}

.footer1 img {
  width: 300px;
}

.footer1 p {
  width: 100%;
  margin-top: 2rem;
  font-weight: 300;
}

.footer2 p {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  font-weight: 300;
}

.footer2 a {
  text-decoration: none !important;
  color: black;
  text-align: left;
}

.footer2 i {
  font-size: 15px;
  margin-top: 5px;
}

.footer2 {
  text-align: left;
  font-size: 0.9rem;
}

.footer2 .circle {
  width: 40px;
  height: 40px;
  border: 2px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
}
