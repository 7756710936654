.recover-button {
  width: 10%;
  border-color: black;
  background-color: black;
  color: white;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
  border-radius: 7px;
}

.recover-input {
  width: 25%;
}

.recover-button:hover {
  border-color: black;
  background-color: black;
  color: #cea977 !important;
}

.recover-button:disabled {
  opacity: 1 !important;
}

.recover-link {
  color: #cea977;
}

.recover-link:hover {
  color: #cea977;
}

@media (max-width: 767px) {
  .recover-button {
    width: 30%;
    border-color: black;
    background-color: black;
    color: white;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 25px;
    border-radius: 7px;
  }

  .recover-input {
    width: 50%;
  }
}
