/* -------------------------------------------------- */

.problem-title {
  font-size: 1.2rem;
  height: 60px;
  margin-right: 2vw;
  border-bottom: 1px solid #ddd;
}

.problem-projects {
  height: calc(100vh - 155px);
  display: flex;
  flex-flow: row nowrap;
  padding: 2vw;
}

.problem-task-icon {
  width: 3.2em;
  height: 3.2em;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #555;
  position: relative;
}

.problem-task-icon::before {
  width: 3.6em;
  height: 3.6em;
  position: absolute;
  content: '.';
  z-index: -1;
  background-color: #ededed;
  border-radius: 50%;
  padding: 1em !important;
}

/* -------------------------------------------------- */

.problem-sidebar {
  width: 20%;
  border-right: 1px solid #d6d6d6;
  text-align: center;
}

.problem-options {
  text-align: left;
  margin: 10% 15% 15% 10%;
  font-size: 1rem;
  font-weight: bolder;
}

.problem-options-font {
  text-decoration: none;
  color: grey;
}

.problem-options-font:hover {
  text-decoration: none;
  color: #555;
}

/* -------------------------------------------------- */

.problem-main {
  width: 80%;
  padding: 0 2%;
  flex-grow: 1;
}

.problem-btn-project {
  color: #555 !important;
  border: 2px solid #555 !important;
  border-radius: 100px !important;
  transition: 0.2s ease all !important;
}

.problem-btn-project:hover {
  background-color: #555 !important;
  color: white !important;
}
