* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.containerBarChart {
  height: 50px;
  width: 90%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.BarChartCustom {
  height: 10px;
  width: 100%;
  background-color: #e9ecef;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.BarChartLabels {
  width: 100%;
  display: flex;
}

.BarChart-line {
  background: #6c6d6e;
  height: 8px;
  margin: auto 0;
  border-radius: 10px;
  transition: 0.9s ease-in-out;
}

.BarChart-circle-start {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #bfaa7f;
  /* margin: -25px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 13px;
  z-index: 99;
}

.BarChart-circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  /* background-color: #7200a7; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 99;
  margin-top: 0%;
  position: absolute;
}

.BarChart-circle-end {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #bfaa7f;
  /* margin: -25px; */
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 13px;
  z-index: 99;
}

.span-flotante {
  position: relative;
  top: 450%;
  z-index: 99;
  color: black;
  font-size: 11px;
}
