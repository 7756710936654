.signup-section {
  height: calc(100vh - 70px);
}

.signup-banner {
  background-image: url('../../assets/img/landing/vectores-29.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.signup-form-icon {
  width: 7vw;
}

.signup-input {
  border-radius: 7px !important;
  margin-top: 2vh !important;
}

.signup-input:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
  border-color: #cea977;
}

.signup-button {
  width: 25vw;
  background-color: black;
  color: white;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
  border-radius: 7px;
}

.signup-button:hover {
  color: #cea977 !important;
}

.signup-button:disabled {
  opacity: 1 !important;
}

.signup-link {
  color: #cea977;
  text-decoration: none;
}

.signup-link:hover {
  color: black;
  text-decoration: none;
}

/* CONDITIONS */
.signup-conditions-section {
  min-height: calc(100vh - 200px);
  background-image: url('../../assets/img/landing/vectores-27.png');
  background-position-x: right;
  background-position-y: bottom;
  background-size: 35vw;
  background-repeat: no-repeat;
  margin-right: 5%;
}

.signup-conditions-button-section {
  height: 130px;
}

.signup-conditions-agreement-section {
  min-height: 700px;
  border-radius: 10px;
  box-shadow: 0px 0px 7px 1px rgb(200, 200, 200);
  margin-left: 8%;
  margin-top: 5%;
  padding: 30px;
  width: 54%;
}

.signup-conditions-button {
  width: 10%;
  background-color: black;
  color: white;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
  border-radius: 7px;
}

.signup-conditions-button:hover {
  color: #cea977 !important;
}

.signup-conditions-button:disabled {
  opacity: 1 !important;
}

/* OPTIONAL */
.signup-optional-section {
  height: calc(100vh - 150px);
  background-image: url('../../assets/img/landing/vectores-27.png');
  background-position-x: right;
  background-position-y: bottom;
  background-size: 35vw;
  background-repeat: no-repeat;
  margin-right: 5%;
  margin-bottom: 80px;
}

.signup-optional-button-section {
  margin-left: 8%;
  width: 54%;
}

.signup-optional-agreement-section {
  border-radius: 10px;
  box-shadow: 0px 0px 7px 1px rgb(200, 200, 200);
  margin-left: 8%;
  margin-top: 10%;
  padding: 30px;
  width: 54%;
}

.signup-optional-button {
  width: 15%;
  background-color: black;
  color: white;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
  border-radius: 7px;
  float: right;
}

.signup-optional-button:hover {
  color: #cea977 !important;
}

.signup-optional-button:disabled {
  opacity: 1 !important;
}

/* CHECKBOX */
.signup-checkbox-label {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 24px;
  width: 24px;
  clear: both;
}

.signup-checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.signup-checkbox-label .signup-checkbox-custom {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border-radius: 3px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 1px solid #555;
}

.signup-checkbox-label input:checked ~ .signup-checkbox-custom {
  background-color: #fff;
  border-radius: 3px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 1px solid #555;
}

.signup-checkbox-label .signup-checkbox-custom::after {
  position: absolute;
  content: '';
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #cea977;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.signup-checkbox-label input:checked ~ .signup-checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 5px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid #cea977;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

/* For Ripple Effect */
.signup-checkbox-label .signup-checkbox-custom::before {
  position: absolute;
  content: '';
  left: 10px;
  top: 10px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
  border: 2px solid #ffffff;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.signup-checkbox-label input:checked ~ .signup-checkbox-custom::before {
  left: -3px;
  top: -3px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  opacity: 0;
  z-index: 999;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

@media (max-width: 767px) {
  .signup-input {
    border-radius: 7px !important;
    margin-top: 2vh !important;
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .signup-button {
    width: 80% !important;
    background-color: black;
    color: white;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 25px;
    border-radius: 7px;
  }

  .signup-form-icon {
    width: 16vw;
  }

  .signup-conditions-agreement-section {
    min-height: 700px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 1px rgb(200, 200, 200);
    margin-left: 8%;
    margin-top: 5%;
    padding: 30px;
    width: 100%;
    padding-bottom: 35%;
  }

  .signup-conditions-button {
    width: 30%;
    background-color: black;
    color: white;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 25px;
    border-radius: 7px;
  }

  .signup-optional-agreement-section {
    border-radius: 10px;
    box-shadow: 0px 0px 7px 1px rgb(200, 200, 200);
    margin-left: 8%;
    margin-top: 10%;
    padding: 30px;
    width: 100%;
  }

  .signup-optional-button {
    width: 30%;
    background-color: black;
    color: white;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 25px;
    border-radius: 7px;
    float: right;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .signup-form-icon {
    width: 11vw;
  }
}

ol > li,
ul > li {
  margin-left: 1rem;
}
