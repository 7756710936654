.create2fa-section {
  height: calc(100vh - 170px);
}

.create2fa-button {
  width: 100%;
  border-color: black;
  background-color: black;
  color: white;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
  border-radius: 7px;
}

.create2fa-button:hover {
  border-color: black;
  background-color: black;
  color: #cea977 !important;
}

.create2fa-button:disabled {
  opacity: 1 !important;
}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 80vh;
}

.wrap .qr {
  width: 300px;
  height: 300px;
  background-color: black;
}

.btn-custom {
  color: #fff !important;
  background-color: #6a6a6a !important;
  border-color: #6a6a6a !important;
  width: 45% !important;
  border-radius: 8px !important;
}
