@font-face {
  font-family: Lato-Regular;
  src: url('./assets/font/Lato-Regular.ttf');
}

@font-face {
  font-family: Lato-Bold;
  src: url('./assets/font/Lato-Bold.ttf');
}

@font-face {
  font-family: Lato-Light;
  src: url('./assets/font/Lato-Light.ttf');
}

* {
  font-family: Lato-Light;
  word-break: normal;
}

.font-bold {
  font-family: Lato-Bold;
  word-break: normal;
}

.border-shadow {
  -webkit-box-shadow: 0px 0px 7px 1px rgb(220, 220, 220);
  -moz-box-shadow: 0px 0px 7px 1px rgb(220, 220, 220);
  box-shadow: 0px 0px 7px 1px rgb(220, 220, 220);
}

.mini-banner {
  background-image: url('./assets/img/landing/vector-14.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 80px;
  width: 100%;
}

.description {
  width: 100%;
  height: 15vh;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 2%;
}

.description textarea:disabled {
  background: white;
}

.superCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control {
  border-radius: 10px;
  border: 1px solid #c3c3c3;
  background: #fafafa;
  margin: 2% 0;
}

b,
strong {
  font-weight: bolder !important;
}

.module-title-question {
  display: flex;
}

.question-title {
  font-size: 20px !important;
}

thead {
  font-size: 18px !important;
}

.center-td {
  text-align: -webkit-center;
}

.toast-message {
  color: #fff !important;
}

.toast-error {
  background-color: #d92f2e !important;
  opacity: 1 !important;
}

.toast-info {
  background-color: #2f96b4 !important;
  opacity: 1 !important;
}

.toast-warning {
  background-color: #f89406 !important;
  opacity: 1 !important;
}

.toast-success {
  background-color: #51a351 !important;
  opacity: 1 !important;
}

.filtroLogs {
  margin: 0px;
  margin-left: 15%;
  width: 50%;
  padding: 7px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #f6f6f6;
}

.filtroLogs:focus {
  outline: 0px;
}

.btn:hover {
  box-shadow: 0 0 0 0 !important;
}

.btn:focus {
  box-shadow: 0 0 0 0 !important;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #cea977;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
}

/* width */
::-webkit-scrollbar {
  border-radius: 10px;
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: #555;
}

/* SWITCH */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20.4px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 15.6px;
  width: 15.6px;
  left: 2.4px;
  bottom: 2.4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #cea977;
}

input:focus + .slider {
  box-shadow: 0 0 1px #cea977;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15.6px);
  -ms-transform: translateX(15.6px);
  transform: translateX(15.6px);
}

.slider.round {
  border-radius: 20.4px;
}

.slider.round:before {
  border-radius: 50%;
}
/* SWITCH */

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.dashboard-buttons {
  width: 40%;
  background-color: black;
  color: white;
  font-weight: bold;
  margin-top: 15px;
  border-radius: 10px;
}

.dashboard-buttons:hover {
  color: #cea977;
}

.dropzone-section {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

.dropzone-section:focus {
  outline: 0px;
}

.dropzone-div:focus {
  outline: 0px;
}
