/* -------------------------------------------------- */

.dashboard {
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  padding: 2%;
  overflow: auto;
}

/* -------------------------------------------------- */

/* .sidebar{
    width: 20% !important;
    border-right:1px solid #d6d6d6 !important;
    text-align: center;
    height: 0vh;
}
.sidebar{
    position: -webkit-sticky; 
    position: sticky; 
    top: 0;
}

.sidebar img{
    width: 80%;
}

.sidebar .options{
    text-align: left;
    margin: 15%;
    font-size: 20px;
    font-weight: bolder;
}

.sidebar .options a {
    text-decoration: none;
    color: black;
}

.sidebar .options a:active{
    color: #cea977;
}

.sidebar .options a:hover{
    color: #cea977;
}

.sidebar .options p{
    margin: 15% 0;
}

.sidebar .options p i{
    margin: 0 4% 0 0 !important;
} */

/* -------------------------------------------------- */

.main {
  padding: 0 2% !important;
  width: 0;
  flex-grow: 1;
}

.dash-div {
  border: 1px solid #a1a1a1;
  border-radius: 10px;
}

.chart-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-div {
  width: 100%;
  height: 90vh;
  overflow: auto;
}

.question-content {
  margin: 2% 4%;
}

.progressBar {
  margin: 2% 5%;
}

.progress {
  height: 0.5em !important;
}

.progress-bar {
  background: #242424a1 !important;
}
